<template>
	<div class="position-relative">
		<div class="w-50 position-absolute mh100 bg-credito" style="bottom: 0px; top:0px; left: 0px; min-height: 100%;"></div>
		<div class="w-50 position-absolute mh100 bg-inversion" style="bottom: 0px; top:0px; right: 0px; min-height: 100%;"></div>
		<div class="position-relative m-auto h-100">
			<div class="row justify-content-center align-items-center h-100 mx-0" style="min-height: calc(100vh - 281px)">
				<div class="col-md-8 col-lg-4">
					<div class="card bg-light rounded-lg" style="border-radius: 1rem;">
						<div class="card-body text-center p-4">
							<div class="row justify-content-center">
								<div class="col-md-12 mb-3">
									<form ref="userForm" v-on:submit.prevent="sendReset()" class="needs-validation was-validated" novalidate>
										<div class="mt-3 mb-5">
											<img src="@/assets/image/logo_dark.webp" class="img-fluid w-50">
										</div>
										<div class="mb-4">
											<input id="email" type="email" name="email" v-model="form.mail" class="form-control" placeholder="Ingresa tu correo" required>
										</div>
										<br>
										<div class="text-center mb-3">
											<button type="submit" class="btn btn-secondary rounded-pill font-weight-bold text-primary" :disabled="loadingReset || !validateForm">
												<BtnLoading v-if="loadingReset" />
												<span v-else>
													Recuperar contraseña
												</span>
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		components: {
			BtnLoading
		},
		data(){
			return {
				validateForm: false,
				loadingReset: false,
				form: {
					mail: ''
				}
			}
		},
		methods: {
			sendReset(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingReset = true
				this.$store.dispatch('resetPassword', this.form).then(response => {
					var data = response.data
					this.form.mail = ''
					window.toastr.info(data.msg, 'Éxito')
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('Error al envíar correo', 'Error')
					}
					return error
				}).then(() => {
					this.loadingReset = false
				})
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
		},
		watch:{
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>