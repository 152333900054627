<template>
	<div>
		Saliendo...
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.logout()
		},
		methods: {
			logout(){
				this.$store.commit('logout')
				this.$router.push({name: 'login'})
			}
		}
	}
</script>