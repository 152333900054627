<template>
	<div class="position-relative">
		<div class="w-50 position-absolute mh100 bg-credito" style="bottom: 0px; top:0px; left: 0px; min-height: 100%;"></div>
		<div class="w-50 position-absolute mh100 bg-inversion" style="bottom: 0px; top:0px; right: 0px; min-height: 100%;"></div>
		<div class="position-relative m-auto h-100">
			<div class="row justify-content-center align-items-center h-100 mx-0" style="min-height: calc(100vh - 164px)">
				<div class="col-md-8 col-lg-4">
					<div class="card bg-light rounded-lg" style="border-radius: 1rem;">
						<div class="card-body text-center p-4">
							<div class="row justify-content-center">
								<div class="col-md-12 mb-3">
									<div class="mt-3 mb-5">
										<img src="@/assets/image/logo_dark.webp" class="img-fluid w-50">
									</div>
									<form ref="userForm" v-on:submit.prevent="updatePassword()" class="needs-validation was-validated" novalidate v-if="validToken">
										<div class="mb-4">
											<input id="password" type="password" name="password" v-model="form.pass" class="form-control" placeholder="Ingresa tu contraseña" minlength="8" required>
										</div>
										<div class="mb-4">
											<input id="password_confirm" type="password" name="password_confirm" v-model="form.pass2" class="form-control" placeholder="Repite tu contraseña" minlength="8" required>
											<div v-if="validatePassword">
												<p v-if="form.pass != '' && form.pass == form.pass2 && form.pass.length>=8" class="text-success">
													Contraseña correcta.
												</p>
												<p v-else class="text-danger">
													Contraseña incorrecta.
												</p>
											</div>
										</div>
										<br>
										<div class="text-center mb-3">
											<button type="submit" class="btn btn-secondary rounded-pill font-weight-bold text-primary" :disabled="loadingReset || !validateForm">
												<BtnLoading v-if="loadingReset" />
												<span v-else>
													Cambiar contraseña
												</span>
											</button>
										</div>
									</form>
									<div v-else>
										<h4 class="text-danger">
											El token no es válido
										</h4>
										<router-link :to="{name:'reset'}" class="btn btn-secondary rounded-pill font-weight-bold text-primary">
											Recuperar contraseña
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	var md5 = require('md5')
	export default {
		components: {
			BtnLoading
		},
		data(){
			return {
				validateForm: false,
				loadingReset: false,
				validatePassword: false,
				validToken: true,
				loadingGetToken: false,
				form: {
					pass: '',
					pass2: '',
					mail: '',
					recovery: ''
				}
			}
		},
		mounted(){
			var horaactual = Math.round(new Date().getTime()/1000.0)
			var res = this.id.split("||")
			if (horaactual <= res[0]) {
				this.getTokenRecovery()
				
			}else{
				this.validToken = false
			}
		},
		methods: {
			updatePassword(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingReset = true
				this.$store.dispatch('updatePassword', {
					recovery: this.form.recovery,
					pass: this.passwordMD5
				}).then(response => {
					this.form.pass = ''
					this.form.pass2 = ''
					window.toastr.info('Contraseña restablecida exitosamente.', 'Éxito')
					this.$router.push({name: 'login'})
					return response
				}).catch(error =>{
					if (error.response) {
						window.toastr.error(error.response.data.msg, 'Error')
					}else{
						window.toastr.error('La contraseña no cumple los requisitos.', 'Error')
					}
					return error
				}).then(() => {
					this.loadingReset = false
				})
			},
			getTokenRecovery(){
				this.loadingGetToken = true
				this.$store.dispatch('getTokenRecovery', {
					recovery: this.id
				}).then(response => {
					var data = response.data || []
					if (data.length > 0) {
						this.form.recovery = data[0].recovery || ''
					}else{
						this.validToken = false
						window.toastr.error("Error", "El acceso no es valido.");
					}
					return response
				}).catch(error =>{
					this.validToken = false
					window.toastr.error("Error", "El acceso no es valido.");
					return error
				}).then(() => {
					this.loadingGetToken = false
				})
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
		},
		computed: {
			passwordMD5(){
				if (this.form.pass!='') {
					return md5(this.form.pass)
				}
				return ''
			},
			id(){
				return this.$route.params.id || -1
			},
		},
		watch:{
			'form':{
				handler(val){
					if (!this.validatePassword && (this.form.pass != "" && this.form.pass2 != "")) {
						this.validatePassword = true
					}
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
	}
</script>